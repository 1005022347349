import styles from './App.module.css';
import globalStyles from './globalStyles.module.css'
import {AppContext} from "./Contexts/AppContext";
import background from "./exported/bg-image.png"
import wwLogo from "./exported/wavewearLogoCustom.png"
import React, {useContext, useEffect} from "react";
import LandingPage from "./Components/LandingPage/LandingPage";
import SecondPage from "./Components/Page2/SecondPage";
import {TermsAndConditions} from "./Components/TermsAndConditions/TermsAndConditions";
import {ThirdPage} from "./Components/Page3/ThirdPage";
import {Video} from "./Components/Video/Video";
import {FourthPage} from "./Components/Page4_5_6/FourthPage";
import {FifthPage} from "./Components/Page4_5_6/FifthPage";
import {SixthPage} from "./Components/Page4_5_6/Page6/SixthPage";
import {Page4Question1} from "./Components/Page4Question1/Page4Question1";
import {Page5Question1} from "./Components/Page5Question1/Page5Question1";
import {SeventhPage} from "./Components/Page7/seventhPage";
import {Result} from "./Components/Result/Result";
import {InvalidUser} from "./Components/errors/InvalidUser";
import {AlreadyPlayed} from "./Components/errors/AlreadyPlayed";
import {CampaignIsOver} from "./Components/errors/CampaignIsOver";
import {FAQ} from "./Components/FAQ/FAQ";

function App() {
    const {page, setPage, getCounters, clickEvent} = useContext(AppContext)

    useEffect(() => {
        getCounters()
    }, [])

    function currentComponent() {
        switch (page) {
            case "start":
                return <LandingPage/>
            case "second":
                return <SecondPage/>
            case "TermsNConditions":
                return <TermsAndConditions/>
            case "FAQ":
                return <FAQ/>
            case "third":
                return <ThirdPage/>
            case "video":
                return <Video/>
            case "fourthPage":
                return <FourthPage/>
            case "Page4Q1":
                return <Page4Question1/>
            case "fifthPage":
                return <FifthPage/>
            case "Page5Q1":
                return <Page5Question1/>
            case  "sixthPage":
                return <SixthPage/>
            case  "seventhPage":
                return <SeventhPage/>
            case  "result":
                return <Result/>
            case "invalidUser":
                return <InvalidUser/>
            case "campaignIsOver":
                return <CampaignIsOver/>
            case "alreadyPlayed":
                return <AlreadyPlayed/>
            default:
                return (<h1>404</h1>)

        }
    }


    return (
        <div className={globalStyles.maxHeight}>
            <header>
                <a href={"https://wavewear.cc/?utm_source=email_win-a-prize_220801&utm_medium=gamification&utm_campaign=kickstarter-cycling"}
                   target="_blank"
                   rel="noopener noreferrer"
                >
                    <img src={wwLogo} alt="wavewear" className={styles.wwLogoButton}/>
                </a>
            </header>
            <div className={`${globalStyles.bgImage} ${globalStyles.flexCenter} ${styles.container} bg-image`}>
                {currentComponent()}
                <div className={styles.footer}
                     style={page === "FAQ" || page === "TermsNConditions" || page === "video" ? {display: "none"} : {}}>
                    <button
                        className={` ${globalStyles.button}`}
                        onClick={() => setPage("FAQ")}>
                        FAQ
                    </button>
                    <button
                        className={` ${globalStyles.button}`}
                        onClick={() => setPage("TermsNConditions")}>
                        Terms & Conditions
                    </button>
                </div>
            </div>
        </div>
    )
}

export default App;
