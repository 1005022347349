import React, {useContext, useEffect} from 'react';
import cloudButton from "../../exported/cloud-button.png";
import {AppContext} from "../../Contexts/AppContext";
import styles from "./LandingPage.module.css"
import globalStyles from "../../globalStyles.module.css"
import Page from "../Page/Page";


const LandingPage = () => {
    const {setPage, clickEvent} = useContext(AppContext)
    return (
        <Page>
            <div className={`${globalStyles.smallTitle} ${styles.welcome} `} style={{padding:"10px"}}>Welcome to
                <br/>
                the WaveWear Cycling Launch Party
            </div>
            <div className={globalStyles.bigTitle}>
                Celebrate with us and win a prize
            </div>
            <div className={globalStyles.text}>We are coming out with<br/>
                new cycling bib shorts on Kickstarter.<br/>
                Unlock a prize by playing a quiz.</div>
            <div></div>
            <button
                className={`${globalStyles.textLg} ${styles.cloudButton} ${globalStyles.bgImage} ${globalStyles.flexCenter} ${globalStyles.button}`}
                onClick={() => clickEvent("start")}
                style={{
                    backgroundImage: `url(${cloudButton})`,
                }}>
                Enter
            </button>
        </Page>)

}

export default LandingPage;
