import React, {useContext} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import circle from "../../exported/circle-video.png"
import styles from "./ThirdPage.module.css"
import {AppContext} from "../../Contexts/AppContext";

export const ThirdPage = () => {
    const {setPage} = useContext(AppContext)
    return (
        <Page>
            <div className={globalStyles.smallTitle} style={{padding:"40px"}}>New cycling bib shorts
                <br/>
                coming on Kickstarter soon
            </div>
            <div className={`${globalStyles.flexCenter} ${styles.container}`}>
                <div className={globalStyles.text}>
                    <span className={styles.responsiveNewLine}>Complete 3 challenges.</span>
                    {'\u00A0'}
                    This is the first one.<br/>
                    Watch a video of our cycling campaign.
                </div>
                <img className={`${globalStyles.button} ${styles.circle}`}
                     onClick={()=>{setPage("video")}}
                     src={circle}></img>
            </div>
        </Page>
    )
}
