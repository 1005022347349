import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"

export const AlreadyPlayed =()=>{
    return(
        <Page>
            <div className={globalStyles.title}>
                You have already played<br/>
                and received a prize.
            </div>
        </Page>
    )
}