import React, {Component} from 'react';
import globalStyles from "../../globalStyles.module.css"
import styles from "./Page.module.css";
import popUpImg from "../../exported/bg-image.png";

class Page extends Component {
    render() {
        return (
            <div className={`${globalStyles.flexCenter} ${globalStyles.page}`}
                 style={this.props.foggy ?
                     {
                         backgroundColor: "rgba(255,255,255,0.5)",
                         margin: 0,
                     }
                     : {backgroundColor: "transparent"}}
            >
                {this.props.popUp ?
                    <div className={`${globalStyles.flexCenter} ${globalStyles.bgImage} ${styles.popUp} ${ this.props.white ? '':"bg-image"}`}
                         style={this.props.white ?
                             {backgroundColor: "white",
                             color:"black"}
                             : {}
                         }
                    >{this.props.children}</div>
                    : this.props.children
                }
            </div>
        );
    }
}

export default Page;