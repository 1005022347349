import React, {useContext} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
// import styles from "./FifthPage.module.css"
import {AppContext} from "../../Contexts/AppContext";
import TripleScheme from "../TripleSheme/TripleScheme";
import CircleImgBtn from "../CircleImgBtn/CircleImgBtn";
import image from "../../exported/circleP4.png"

export const FourthPage = () => {
    const {setPage} = useContext(AppContext)
    return (
        <TripleScheme title={"Feel and function better with WaveWear"}
                      content={["1 of 3 challenges completed.", "This time solve a quiz about WaveWear!"]}>
            <CircleImgBtn
                image={image}
                text={"How much muscle fatigue does WaveWear reduce?"}
                textStyles={{color:"#FFD691"}}
                btnOnClick={()=>{setPage("Page4Q1")}}
                btnText={"Start"}
            ></CircleImgBtn>

        </TripleScheme>

    )
}