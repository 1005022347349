import React, {useContext} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
// import styles from "./FifthPage.module.css"
import {AppContext} from "../../Contexts/AppContext";
import TripleScheme from "../TripleSheme/TripleScheme";
import CircleImgBtn from "../CircleImgBtn/CircleImgBtn";
import image from "../../exported/circleP5.png"

export const FifthPage = () => {
    const {setPage} = useContext(AppContext)
    return (
        <TripleScheme title={"WaveWear supports your body\n" +
            "to go further while being safer"}
                      content={["2 of 3 challenges completed.",
                          "One more quiz to go. You can do it!" ]}>
            <CircleImgBtn
                image={image}
                text={"How does\n" +
                    "WaveWear’s \n" +
                    "bib shorts\n" +
                    "provide support?"}
                textStyles={{color:"#000000"}}
                btnOnClick={()=>{setPage("Page5Q1")}}
                btnText={"Start"}
            ></CircleImgBtn>

        </TripleScheme>

    )
}