import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import YouTube from "react-youtube";
import styles from "./Video.module.css"
import {useContext} from "react";
import {AppContext} from "../../Contexts/AppContext";

export const Video = () => {
    const {clickEvent} = useContext(AppContext)
    const opts = {
        playerVars: {
            // autoplay: 1,
            controls: 0,
            enablejsapi: 1,
            rel: 0,
            progressBar: true,
        }
    }
    return (
        <Page foggy={true}>
            <YouTube
                opts={opts}
                className={styles.yt2}
                videoId={"mAAzo7KrXVI"}
                onEnd={() => {
                    clickEvent("video")
                }}/>
            {/*<div className={`${globalStyles.flexCenter} ${styles.popUp}`}>*/}

            {/*    <div className={styles.videoInfo}>Watch WaveWear Bib Shorts Video</div>*/}
            {/*    /!*<div className={styles.videoPlayer}>*!/*/}
            {/*    /!*<video className={styles.videoPlayer}*!/*/}
            {/*    /!*       autoPlay={true}*!/*/}
            {/*    /!*       onEnded={() => {*!/*/}
            {/*    /!*           clickEvent("video")*!/*/}
            {/*    /!*       }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*    <source src={VideoMP4} type="video/mp4"/>*!/*/}
            {/*    /!*    Your browser does not support the video tag.*!/*/}
            {/*    /!*</video>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className={styles.videoPlayer}>*!/*/}
            {/*    /!*    /!*<iframe*!/*!/*/}
            {/*    /!*    /!*    src="https://www.youtube.com/embed/XwdI2zeHGcQ?controls=0"*!/*!/*/}
            {/*    /!*    /!*    title="YouTube video player" frameBorder="0"*!/*!/*/}
            {/*    /!*    /!*    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*!/*!/*/}
            {/*    /!*    /!*    allowFullScreen*!/*!/*/}
            {/*    /!*    /!*    onStateChange={() => {*!/*!/*/}
            {/*    /!*    /!*        clickEvent("video")*!/*!/*/}
            {/*    /!*    /!*    }}*!/*!/*/}
            {/*    /!*    /!*>*!/*!/*/}

            {/*    /!*    /!*</iframe>*!/*!/*/}
            {/*    /!*    <YouTube*!/*/}
            {/*    /!*        opts={opts}*!/*/}
            {/*    /!*        className={styles.yt}*!/*/}
            {/*    /!*        videoId={"XwdI2zeHGcQ"}*!/*/}
            {/*    /!*        onEnd={() => {*!/*/}
            {/*    /!*            clickEvent("video")*!/*/}
            {/*    /!*        }}*!/*/}
            {/*    /!*    />*!/*/}
            {/*    /!*</div>*!/*/}
            {/*<button onClick={()=>{clickEvent("video")}} style={{color:"black"}}>skip</button>*/}

            {/*</div>*/}
        </Page>
    )
}