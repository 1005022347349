import React, {useContext} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import styles from "./seventhPage.module.css"
import {AppContext} from "../../Contexts/AppContext";
import card from "../../exported/card.png"

export const SeventhPage = () => {
    const {clickEvent} = useContext(AppContext)
    return(
        <Page>
            <div className={globalStyles.title}>Now it is time for<br/>
                you to choose your prize</div>
            <div className={globalStyles.textLg}>
                Flip the card to reveal your prize by tapping.</div>
            <div className={styles.cardsContainer}>
                <img alt="card" onClick={()=>{openIt(1)}} src={card}/>
                <img alt="card" onClick={()=>{openIt(2)}} src={card}/>
                <img alt="card" onClick={()=>{openIt(3)}} src={card}/>
            </div>
            <div></div>
            <div></div>

        </Page>
    )
    function openIt(n){
        console.log("xd")
        clickEvent("reward")
    }
}