import React, {useContext, useEffect, useState} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import styles from "./Result.module.css"
import card from "../../exported/card.png"
import {AppContext} from "../../Contexts/AppContext";
import prize1 from "../../exported/rewardSleeve.png"
import prize2 from "../../exported/reward15pOff.png"
import prize3 from "../../exported/rewardCream.png"

export const Result = () => {
    const [code, setCode] = useState("Loading...")
    const [prize, setPrize] = useState(card)
    const {rewardType,rewardCode} = useContext(AppContext)
    useEffect( ()=>{
        async function data() {
            const type = await rewardType()
            const code = await rewardCode()
            console.log(type)
            if(code!==undefined)
                setCode(code)

            console.log(code)
            switch (type) {
                case 1:setPrize(prize1);break;
                case 2:setPrize(prize2);break;
                case 3:setPrize(prize3);break;
            }
        }
        data()
    },[])
    const [open, animation] = useState(false)
    return (
        <Page>
            <div className={globalStyles.title} style={{padding: "30px"}} onClick={() => {
                animation(!open)
            }}>Congratulations
            </div>
            <div
                className={globalStyles.flexCenter}
                style={{flex: 1, position:"relative", maxHeight:"50vh",minHeight:"300px"}}>
                <div onClick={() => {
                    animation(true)
                }}
                     className={`${styles.card} ${globalStyles.bgImage} ${open ? styles.onOpen : null}`}
                     style={{backgroundImage:`url(${card})`}}
                >

                </div>
                <div
                    className={`${styles.card} ${globalStyles.bgImage} ${styles.card2} ${open ? styles.onOpen2 : null}`}
                    style={{backgroundImage:`url(${prize})`}}>
                </div>

            </div>
            <div className={styles.bottomTxt}>
                <h1 className={`${styles.codeAnimation} ${open?styles.onOpen2:styles.onOpen3}`}>Code: {code}</h1>
                <div className={globalStyles.text}>Purchase over $35 from wavewear.cc</div>
                <div className={globalStyles.text}>Redeem your prize by [date]</div>
            </div>
        </Page>
    )
}