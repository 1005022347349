import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import styles from "./TnC.module.css"
import {useContext} from "react";
import {AppContext} from "../../Contexts/AppContext";
export const TermsAndConditions = () => {
    const {backPage} = useContext(AppContext)
    return (
        <Page foggy={true} popUp={true}>
            <button className={`${globalStyles.button} ${globalStyles.buttonRounded} ${styles.back}`}
                onClick={()=>{backPage()}}
            >Back</button>
            <div className={globalStyles.bigTitle} style={{padding: "10px 0"}}>Terms and Conditions</div>
            <div className={globalStyles.textLg}>OFFICIAL RULES</div>
            <div className={styles.mainText}>
<p>                Important: Please read these Official Rules before entering The WaveWear Cycling Launch Party (“Sweepstakes”). By participating in this Sweepstakes, you agree to be bound by these Official Rules and represent that you satisfy all of the eligibility requirements below and agree to</p>
<p></p>
<p>                NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN THIS SWEEPSTAKES. PURCHASE OR PAYMENT WILL NOT INCREASE ODDS OF WINNING. Sweepstakes void where prohibited by law. Subject to applicable federal, state, and local laws.</p>
<p></p>
<p>                Eligibility: Sweepstakes is open to only individuals who are residents of the 50 United States or District of Columbia and are at least 19 years old (or the age of majority in their jurisdiction of residence, whichever is older) at the time of entry.</p>
<p></p>
<p>                Sponsorship: The Sweepstakes sponsor is Wave Company Ltd., located at DMC Hi-Tech Industry Center #713, Seongam-ro 330, Mapo-gu, Seoul, South Korea (zip 03920) (“Sponsor”). Sponsor will conduct the Sweepstakes substantially as described in these Official Rules.</p>
<p></p>
<p>                Sweepstakes Period: The Sweepstakes starts on July 20th and ends on August 22st, 2022 EST. All entries must be received during the Sweepstakes Period and meet other requirements in these Official Rules to be eligible to win a prize.</p>
<p></p>
<p>                How to Enter: All entries submitted in accordance with these Official Rules are referred to in these Official Rules as “Eligible Entries.” There are two ways to enter into the Sweepstakes and become an eligible entrant (“Entrant”). Each Entrant is limited to one entry per Sweepstakes Period.</p>
<p>                (1) PRIMARY ENTRY: In order to be entered into any of the Sweepstake Periods, an Entrant must sign up for the WaveWear email list via wavewear.cc.</p>
<p>                (2) ALTERNATIVE ENTRY: In order to be entered into any of the Sweepstakes Periods without completing the primary entry conditions, an Entrant must send an email with the Entrant’s first and last name, mailing address, email address, date of birth, and telephone number to support@wavecompany.net with the subject title “Email Sign-up Sweepstakes,” prior to the conclusion of the applicable Sweepstakes Period.</p>
<p></p>
<p>                Prizes: Prizes are coupon codes that are able to use at the WaveWear website via wavewear.cc. Coupon codes will expire 2022 September 30th EST.</p>
<p>                Prize (1) - BOGO sleeve (1ea) of your choice when buying over $35 on the WaveWear website. Options for the BOGO sleeve are K2 knee sleeve, K1 knee sleeve, E2 elbow sleeve, and F1 forearm sleeve. Twenty-two of these prizes are available.</p>
<p>                Prize (2) - Two of WaveWear’s Activity Boosting Performance Sports Cream when buying over $25 on the WaveWear website. 400 creams, thus 200 of these prizes are available.</p>
<p>                Prize (3) - 15% Discount code to use on the WaveWear website.</p>
<p></p>
<p>                Prize Selection and Notification: Everyone completing the sweepstakes will receive a prize. Prizes will be randomly allocated from Prize (1), Prize (2), and Prize (3). The coupon codes for the prizes will be notified to each person at the end of the via email to the email address they entered the Sweepstakes when the following the participation of the Sweepstakes.</p>
<p>                Disclaimer: Sponsor and any of their respective parent companies, subsidiaries, affiliates, directors, officers, professional advisors, consultants, contractors, legal counsel, public relations firms, employees and advertising, fulfillment and marketing agencies (collectively, the “Released Parties”) will not be responsible for: (a) any late, lost, misrouted, garbled or distorted or damaged transmissions or entries; (b) phone, electronic, hardware, software, network, Internet, or other computer or communications-related malfunctions or failures beyond Sponsor or the Released Parties’ reasonable control; (c) any Sweepstakes disruptions, injuries, losses or damages caused by events beyond the reasonable control of Sponsor or the Released Parties by non-authorized human intervention; or (d) any printing or typographical errors in any materials associated with the Sweepstakes.</p>
<p>                Limitations of Liability; Releases: By entering the Sweepstakes, you release Sponsor and all Released Parties from any liability whatsoever, and waive any and all causes of action related to any claims, costs, injuries, losses, or damages of any kind arising out of or in connection with the Sweepstakes or delivery, misdelivery, acceptance, possession, use of or inability to use any prize (including claims, costs, injuries, losses and damages related to rights of publicity or privacy, defamation or portrayal in a false light, whether intentional or unintentional), whether under a theory of contract, tort (including negligence), warranty or other theory.</p>
<p>                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE SPONSOR OR THE RELEASED PARTIES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING, NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED TO YOUR PARTICIPATION IN THE SWEEPSTAKES OR USE OR INABILITY TO USE ANY EQUIPMENT PROVIDED FOR USE IN THE SWEEPSTAKES OR ANY PRIZE, EVEN IF A RELEASED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
<p>                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE RELEASED PARTIES (JOINTLY) ARISING OUT OF OR RELATING TO YOUR PARTICIPATION IN THE SWEEPSTAKES OR USE OF OR INABILITY TO USE ANY EQUIPMENT PROVIDED FOR USE IN THE SWEEPSTAKES OR ANY PRIZE EXCEED $10. THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT EXCLUDE OR LIMIT LIABILITY FOR PERSONAL INJURY OR PROPERTY DAMAGE CAUSED BY PRODUCTS PURCHASED OR RENTED FROM THE SPONSOR, OR FOR THE RELEASED PARTIES’ GROSS NEGLIGENCE, INTENTIONAL MISCONDUCT, OR FOR FRAUD.</p>
<p>                Additional Conditions: Sponsor reserves the right, in its sole discretion, to cancel, terminate, modify, extend or suspend this Sweepstakes should (in its sole discretion) virus, bugs, non-authorized human intervention, fraud or other causes beyond its control corrupt or affect the administration, security, fairness or proper conduct of the Sweepstakes. In such case, Sponsor will send all eligible entries received prior to and/or after (if appropriate) the action taken by Sponsor. Sponsor reserves the right, at its sole discretion, to disqualify any individual it finds, in its sole discretion, to be tampering with the entry process or the operation of the Sweepstakes or website. Sponsor may prohibit an entrant from participating in the Sweepstakes or receiving a prize if, in its sole discretion, it determines that said entrant is attempting to undermine the legitimate operation of the Sweepstakes by cheating, hacking, deception, or other unfair playing practices (including the use of automated quick entry programs) or intending to annoy, abuse, threaten or harass any other entrants or Sponsor representatives.</p>
<p>                Participation in Sweepstakes and acceptance of prize constitutes each winner’s permission for Sponsor to use his/her name, address (city and state), likeness, photograph, picture, portrait, voice, biographical information and/or any statements made by each winner regarding the Sweepstakes or Sponsor for advertising and promotional purposes without notice or additional compensation, except where prohibited by law.</p>
<p>                The invalidity or unenforceability of any provision of these rules shall not affect the validity or enforceability of any other provision. In the event that any provision is determined to be invalid or otherwise unenforceable or illegal, these rules shall otherwise remain in effect and shall be construed in accordance with their terms as if the invalid or illegal provision were not contained herein.</p>
<p>                Governing Law. These Official Rules shall be governed by laws of the Republic of Korea.</p>


            </div>

        </Page>
    )
}