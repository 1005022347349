import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"

export const InvalidUser =()=>{
    return(
        <Page>
            {/*<div className={globalStyles.bigTitle}>Sorry :(</div>*/}
            <div className={globalStyles.title}>Error: this link is invalid</div>
        </Page>
    )
}