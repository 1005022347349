import React, {Component} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import styles from "./TripleScheme.module.css"

export default class TripleScheme extends Component {
    render() {
        return (
            <Page>
                <div className={globalStyles.flexCenter} style={{
                    margin: "10px 40px",
                    flex:1,
                }}>
                    <div className={globalStyles.smallTitle}>{this.props.title}</div>
                    <div className={`${globalStyles.flexCenter} ${styles.container}`}>
                        {this.props.content ? <div className={globalStyles.text}>
                            <span className={styles.responsiveNewLine}>{this.props.content[0]}</span>
                            {'\u00A0'}
                            {this.props.content[1]}
                        </div> : null}
                        {this.props.children}
                    </div>
                </div>
            </Page>
        )
    }
}