import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import styles from "./FAQ.module.css"
import {useContext} from "react";
import {AppContext} from "../../Contexts/AppContext";
export const FAQ = () => {
    const {backPage} = useContext(AppContext)
    return (
        <Page foggy={true} popUp={true}>
            <button className={`${globalStyles.button} ${globalStyles.buttonRounded} ${styles.back}`}
                    onClick={()=>{backPage()}}
            >Back</button>
            <div className={globalStyles.title} style={{padding: "0"}}>FAQ</div>
            <div className={styles.questions}>
                <div> 1.       Can I participate in the WaveWear Cycling Launch Party?</div>
                <div> You can participate in the WaveWear Cycling Launch Party only if you are a resident of the 50 United States or the District of Columbia and are at least 19 years old (or the age of majority in your jurisdiction of residence, whichever is older).</div>
                <div> 2.       How can I access the WaveWear Cycling Launch Party?</div>
                <div> You can access the WaveWear Cycling Launch Party if you have subscribed to the WaveWear email list via the WaveWear website at wavewear.cc.
                <br/> You can also participate by sending an email to support@wavecompany.net with the subject title “Email Sign-up Sweepstakes”.</div>
                <div> 3.       Do I need to complete all the challenges to earn my reward?</div>
                <div> Yes, you need to complete the challenges to win a reward.</div>
                <div> 4.       I think I'm having technical issues. What should I do?</div>
                <div> Please contact us at support@wavecompany.net and we will help you with the technical issue.</div>
                <div> 5.       What are the prizes for completing the challenge?</div>
                <div> You will win one out of three prizes.
                <br/> BOGO sleeve (1ea) of your choice or two of WaveWear’s Activity Boosting Performance Sports Cream (for chafing prevention) or a 15% discount code to use on the WaveWear website. To learn more please check the Terms and Conditions.</div>
                <div> 6.       Is winning a prize guaranteed?</div>
                <div> Yes. Anyone that enters and completes the challenges is guaranteed to win a prize.</div>
                <div> 7.    Do I need to sign up to the WaveWear website to participate?</div>
                <div> No, you do not need to be a member of the WaveWear website to participate. However, to redeem the prize you need to sign up on the WaveWear website.
                <br/> To participate, you need to be subscribed to the WaveWear email list or email support@wavecompany.net with the subject title “Email Sign-up Sweepstakes”.</div>
                <div> 8.   Do I have to purchase anything to win?</div>
                <div> No, you do not have to purchase anything to win nor does purchasing increase your chance of winning. As long as you enter and finish the challenge, you will receive a prize.</div>
                <div> 9.   How do I collect my prize?</div>
                <div> You will receive a randomized coupon code for your prize via email to the email address you entered within five days following the completion of the Wave Wear Launch Party.</div>
                <div> 10.   Until when can I redeem my prize?</div>
                <div> You can use the coupon code for your prize until 2022 August 22nd.</div>
            </div>

        </Page>
    )
}