import React, {Component} from 'react'

export const AppContext = React.createContext()

class AppContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "start"
        }
        this.urlParams = new URLSearchParams(window.location.search);
        this.email = this.urlParams.get("email")
        this.myHeaders = new Headers();
        this.authHeaders = new Headers();
        this.memberURL = "https://api.wayfarerpoints.com/public/rule-engine/members"
        this.countersURL = "https://api.wayfarerpoints.com/public/rule-engine/counters"
        this.eventURL = "https://api.wayfarerpoints.com/public/rule-engine/events"
        this.myHeaders.append("Authorization", "374f57f3-57f7-432d-bcaa-5e367fca5eb4")
        this.myHeaders.append("X-RE-MV", this.email)
        this.authHeaders.append("Authorization", "374f57f3-57f7-432d-bcaa-5e367fca5eb4")
        this.authHeaders.append("X-RE-MV", this.email)
        this.myHeaders.append("content-type", "application/json")
    }

    setPage = (newPage) => {
        this.oldPage = this.state.page
        this.setState({page: newPage})
    }
    back = () => {
        this.setPage(this.oldPage)
    }
    rewardType = async () => {
        let res = await fetch(this.countersURL, {
            method: "GET",
            headers: this.authHeaders,
        })
        res = await res.json()
        for(let r of res){
            if(r.key === "rewardType")
                return r.value
        }
    }
    rewardCode = async () => {
        let res = await fetch(this.memberURL, {
            method: "GET",
            headers: this.authHeaders,
        })
        res = await res.json()
        return res.data.rewardCode
    }
    getCounters = () => {
        //MOCK
        // return
        //ENDMOCK

        fetch(this.countersURL, {
            method: "GET",
            headers: this.myHeaders,
        }).then(r => {
            switch (r.status) {
                case 200:
                    r.json().then(j => this.setPageByCounters(j));
                    break;
                case 404:
                    this.setPage("campaignIsOver");
                    break;
                default:
                    this.setPage("invalidUser");
                    break;
            }
        })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    setPageByCounters = (arr) => {
        const events = ["started", "video", "question1", "question2", "reward"]
        const redirectToIfFalse = {
            started: "start",
            video: "third",
            question1: "fourthPage",
            question2: "fifthPage",
            reward: "sixthPage"
        }
        const eventsInObj = {}
        arr.forEach((e) => {
            if (e.value)
                eventsInObj[e.key] = e.value
        })
        console.log(eventsInObj)
        for (let e of events) {
            if (!eventsInObj[e]) {
                console.log(e)
                return this.setPage(redirectToIfFalse[e])
            }
        }
        this.setPage("result")
    }
    clickEvent = (type) => {

        //MOCK
        const redirect = {
            start: "third",
            video: "fourthPage",
            question1: "fifthPage",
            question2: "sixthPage",
            reward: "result"
        }
        if (type !== "reward")
            this.setPage(redirect[type])
        // return this.setPage(redirect[type])
        //MOCKEND

        const body = {
            "type": "click",
            "data": {
                "place": type
            }
        }
        console.log(body)
        fetch(this.eventURL, {
            method: "POST",
            headers: this.myHeaders,
            body: JSON.stringify(body)
        }).then(r => {
            if (type === "reward")
                this.getCounters()
        })

    }

    render() {
        return (
            <AppContext.Provider value={{
                ...this.state,
                setPage: this.setPage,
                getCounters: this.getCounters,
                clickEvent: this.clickEvent,
                backPage: this.back,
                rewardType: this.rewardType,
                rewardCode: this.rewardCode
            }}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}

export default AppContextProvider
