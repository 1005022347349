import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"

export const CampaignIsOver =()=>{
    return(
        <Page>
            <div className={globalStyles.title}>Sorry,<br/>
                the campaign is now over.</div>
        </Page>
    )
}