import React, {Component} from 'react';
import style from "./Circle.module.css";
import globalStyles from "../../globalStyles.module.css";

class CircleImgBtn extends Component {
    render() {
        return (
            <div
                className={`${style.circle} ${globalStyles.flexCenter} ${globalStyles.bgImage} ${this.props.customContent ? style.custom : null}`}
                style={{backgroundImage: `url(${this.props.image})`}}

            >
                {this.props.customContent ? this.props.children :
                    <div className={style.textInCircle}
                         style={this.props.textStyles}>{this.props.text}</div>}
                {!this.props.buttonHref ?
                    <button
                        className={`${globalStyles.button} ${style.nextButton} `}
                        onClick={this.props.btnOnClick}>
                        {this.props.btnText}
                    </button> :
                    <a href={this.props.buttonHref}
                       target="_blank"
                       rel="noopener noreferrer">
                        <button
                            className={`${globalStyles.button} ${style.nextButton} `}
                            onClick={this.props.btnOnClick}>
                            {this.props.btnText}
                        </button>
                    </a>
                }
            </div>
        );
    }
}

export default CircleImgBtn;