import React, {Component, useContext} from 'react';
import {AppContext} from "../../Contexts/AppContext";
import globalStyles from "../../globalStyles.module.css"
import style from "./SecondPage.module.css"
import Page from "../Page/Page";

const SecondPage = () => {
    const {page, setPage} = useContext(AppContext)

    return (
        <Page>
            <div className={`${globalStyles.flexCenter} ${style.circle} `}>
                <div  className={style.textInCircle}>Complete<br/>
                    a series of tasks<br/>
                    to win a prize.
                </div>

                <button
                    className={`${globalStyles.button} ${style.nextButton} `}
                    onClick={() => setPage("third")}>
                    Next
                </button>
            </div>
        </Page>
    )
}

export default SecondPage;