import React, {useContext, useState} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import styles from "./Page4Question1.module.css"
import {AppContext} from "../../Contexts/AppContext";
import incorrectPNG from "../../exported/incorrect.png"
import correctPNG from "../../exported/correct.png"


export const Page4Question1 = () => {
    const P4Answer = (props) => {
        return (
            <div className={`${globalStyles.flexCenter} ${globalStyles.button}`}
                 onClick={() => {
                     if (answer === null) {
                        setAnswer(props.ansNum)
                     }
                 }}
            >
                <div className={`${globalStyles.flexCenter} ${styles.ansImg}`}>
                    {props.value}%
                    <div className={globalStyles.bgImage}
                         style={answer === props.ansNum ?
                             {
                                 backgroundImage: `url(${
                                     props.correct ?
                                         correctPNG :
                                         incorrectPNG
                                 })`
                             }
                             : {border: "2px solid black"}
                         }
                    /></div>
            </div>
        )
    }
    const {clickEvent} = useContext(AppContext)
    const [answer, setAnswer] = useState(null)
    return (
        <Page foggy={true} popUp={true} white={true}>

            <div className={`${globalStyles.smallTitle} ${styles.smallTitle}`}
                style={{color: "#1F275B"}}
            >How much muscle fatigue does WaveWear reduce?</div>
            <div style={answer!==null&&answer!==3?{color: "#1F275B"}:{opacity:0}}
                className={globalStyles.text}>Sorry, your choice is incorrect,<br/>
                Click below to reset the board and try again.
            </div>

            <div className={`${globalStyles.flexCenter} ${styles.ansContainer}`}>
                {P4Answer({
                        ansNum: 1,
                        value: 0,
                        correct: false,
                    }
                )}
                {P4Answer({
                        ansNum: 2,
                        value: 15,
                        correct: false,
                    }
                )}
                {P4Answer({
                        ansNum: 3,
                        value: 35,
                        correct: true,
                    }
                )}
            </div>
            <button className={`${globalStyles.button} ${styles.resNextBtn} ${globalStyles.buttonRounded} ${globalStyles.text}`}
                    style={answer === 3 ? {backgroundColor:"#FFD691",color:"black"}: {}}
                    onClick={() => {
                        answer !== 3 ? reset() : nextPage()
                    }}
            >{answer === 3 ? "Next" : "Reset"}</button>
            <div></div>
        </Page>
    )

    function reset() {
        setAnswer(null)
    }

    function nextPage() {
        if(answer===3){
            clickEvent("question1")
        }
    }
}