import React, {useContext, useState} from "react";
import Page from "../Page/Page";
import globalStyles from "../../globalStyles.module.css"
import styles from "./Page5Question1.module.css"
import {AppContext} from "../../Contexts/AppContext";
import incorrectPNG from "../../exported/incorrectBigIMG.png"
import correctPNG from "../../exported/correctBigIMG.png"


export const Page5Question1 = () => {
    const P5Answer = (props) => {
        return (
            <div className={`${globalStyles.flexCenter} ${globalStyles.button}`}
                 onClick={() => {addAnswer(props.ansNum)}}
            >
                <div className={`${globalStyles.flexCenter} ${styles.ansImg}`}>
                    {props.value}
                    <div className={globalStyles.bgImage}
                         style={answer.includes(props.ansNum) ?
                             {
                                 backgroundImage: `url(${
                                     props.correct ?
                                         correctPNG :
                                         incorrectPNG
                                 })`
                             }
                             : {
                                 border: "2px solid black",

                                 boxSizing: "border-box"
                             }
                         }
                    />
                </div>
            </div>
        )
    }
    const {clickEvent} = useContext(AppContext)
    const [answer, setAnswer] = useState([])

    function addAnswer(newAnswer){
        if(answerState()!=null)
            return;
        if(answer.includes(newAnswer))
            return;
        setAnswer([...answer, newAnswer])
    }
    function answerState(){
        let falseCounter = 0
        let trueCounter = 0
        answer.forEach(a=>{
            switch (a){
                case 1:
                case 3:trueCounter++; break;
                case 2:
                case 4:falseCounter++; break;
            }
        })
        if(falseCounter!==0){
            return 0
        }
        if(trueCounter===2)
            return 1
        return null
    }

    return (
        <Page foggy={true} popUp={true} white={true}>

            <div className={`${globalStyles.smallTitle} ${styles.smallTitle}`}
                 style={{color: "#1F275B"}}
            >How does WaveWear bib shorts provide muscle support?
            </div>
            <div className={globalStyles.text} style={{color: "#1F275B"}}> (Choose 2)</div>


            <div style={answerState()===0 ? {color: "#1F275B"} : {opacity: 0}}
                 className={globalStyles.text}>Sorry, one of your choices is incorrect,<br/>
                Click below to reset the board and try again.
            </div>
            <div className={`${globalStyles.flexCenter} ${styles.ansContainer}`}>
                {P5Answer({
                        ansNum: 1,
                        value: "built-in silicone taping",
                        correct: true,
                    }
                )}
                {P5Answer({
                        ansNum: 2,
                        value: "magic sprinkles",
                        correct: false,
                    }
                )}
                {P5Answer({
                        ansNum: 3,
                        value: "compression",
                        correct: true,
                    }
                )}
                {P5Answer({
                        ansNum: 4,
                        value: "smart fabric",
                        correct: false,
                    }
                )}
            </div>
            <button className={`${globalStyles.button} ${styles.resNextBtn} ${globalStyles.buttonRounded} ${globalStyles.text}`}
                    style={answerState()===1 ? {backgroundColor:"#FFD691",color:"black"}: {}}
                    onClick={() => {
                        answerState()===1 ? nextPage() : reset()
                    }}
            >{answerState()===1 ? "Next" : "Reset"}</button>
        </Page>
    )

    function reset() {
        setAnswer([])
    }

    function nextPage() {
        if (answerState()===1) {
            clickEvent("question2")
        }
    }
}