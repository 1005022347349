import React, {useContext} from "react";
import Page from "../../Page/Page";
import globalStyles from "../../../globalStyles.module.css"
import styles from "./page6.module.css"
import {AppContext} from "../../../Contexts/AppContext";
import TripleScheme from "../../TripleSheme/TripleScheme";
import CircleImgBtn from "../../CircleImgBtn/CircleImgBtn";
import image from "../../../exported/circleP6.png"

export const SixthPage = () => {
    const {setPage} = useContext(AppContext)
    return (
        <TripleScheme title={"Great!\n" +
            "You have completed all stages"}>
            <CircleImgBtn
                image={image}
                customContent={true}
                textStyles={{color: "#000000"}}
                btnOnClick={() => {
                    setPage("seventhPage")
                }}
                btnText={"Explore"}
                buttonHref={"https://www.kickstarter.com/projects/wavewear/cycling-wear-with-built-in-muscle-support?utm_source=email_win-a-prize_220801&utm_medium=gamification&utm_campaign=kickstarter-cycling"}
            >
                <div className={`${globalStyles.textLg} ${styles.custom} `}>
                    Take a look at our<br/>
                    <span style={{color: "#00CF73", display: "inline-block"}}>Kickstarter</span> as we <br/>
                    get your prize ready.
                </div>
                <div className={globalStyles.text} style={{color:"black"}}>
                    Click “Get Notified”<br/>
                    while you are at it!
                </div>
            </CircleImgBtn>
            <button className={`${globalStyles.buttonRounded} ${globalStyles.button} ${styles.nextBtn}`}
                    onClick={() => {
                        setPage("seventhPage")
                    }}
            >Next
            </button>

        </TripleScheme>

    )
}